import {useContext, useEffect, useRef, useState} from "react";

import './NoteList.scss'
import AppContext from "../../context/AppContext";
import NotePreview from "../NotePreview";
import AppIcon, {AppIconName} from "../AppIcon";
import useIndexedDB from "../../database/IndexedDB";
import {NoteObject} from "../../objects/NoteObject";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import BottomToolbar from "../toolbar/BottomToolbar";
import {downloadAsFile} from "../../lib";
import newExport from "../../objects/SFNotesExport";
import FileDropper from "../FileDropper";
import useNoteImporter from "../../lib/NoteImporter";
import BottomContainer from "../BottomContainer";
import FolderTree from "../FolderTree";
import NoteFolder from "../../objects/NoteFolder";
import NoteContext from "../../context/NoteContext";

type Props = {
    folderId: SpecialFolders | string
    title: string
}

export enum SpecialFolders {
    All = 'all',
    Deleted = 'deleted',
}

export default (props: Props) => {
    const navigate = useNavigate()
    const location = useLocation()

    const db = useIndexedDB()
    const importer = useNoteImporter()

    const { t } = useTranslation()

    const [ loading, setLoading ] = useState<boolean>(false)
    const [ selectionMode, setSelectionMode ] = useState<boolean>(false)
    const [ selectedNotes, setSelectedNotes ] = useState<NoteObject[]>([])
    const [ bulkAction, setBulkAction ] = useState<string>('')
    const [ showFolderTree, setShowFolderTree ] = useState(false)

    const [ notes, setNotes ] = useState<NoteObject[]>([])

    const {
        activateBottomToolbar,
        deactivateBottomToolbar,
        appConfirm,
    } = useContext(AppContext)

    const loadNotes = async () => {
        setSelectionMode(false)
        setSelectedNotes([])
        console.log('LOAD', props)

        let filters: { deleted?: boolean, folderId?: string } = {}
        if (props.folderId === SpecialFolders.All) {
            filters.deleted = false
        } else if (props.folderId === SpecialFolders.Deleted) {
            filters.deleted = true
        } else {
            filters.deleted = false
            filters.folderId = props.folderId
        }

        setLoading(true)
        setNotes(await db.getNotes(filters))
        setLoading(false)
    }

    const onSelect = (note: NoteObject) => {
        if (selectedNotes.includes(note)) {
            setSelectedNotes(selectedNotes.filter(n => n.id !== note.id))
        } else {
            setSelectedNotes([
                ...selectedNotes,
                note,
            ])
        }
    }

    useEffect(() => {
        if (selectedNotes.length < 1) {
            if (showFolderTree) {
                setShowFolderTree(false)
            }
        }
    }, [ selectedNotes ]);

    const startSelectionMode = (e: any) => {
        e.preventDefault()
        setSelectionMode(true)
    }

    const exitSelectionMode = () => {
        setSelectedNotes([])
        setSelectionMode(false)
    }

    const onFolderClick = async (folder: NoteFolder) => {
        if (await appConfirm('Move note', folder.title)) {
            await Promise.all(selectedNotes.map(note => {
                note.folderId = folder.id
                return db.updateNote(note)
            }))
        }
    }

    useEffect(() => {
        (async () => {
            switch (bulkAction) {
                case 'delete':
                    if (await appConfirm(
                        t('deleteNotes', {count: selectedNotes.length}),
                        t('confirm.deleteNotes', {count: selectedNotes.length}))
                    ) {
                        await db.deleteNotes(selectedNotes.map(n => n.id))
                        setSelectedNotes([])
                        setSelectionMode(false)
                    }
                    break
                case 'deletePermanent':
                    if (await appConfirm(
                        t('deleteNotes', {count: selectedNotes.length}),
                        t('confirm.deleteNotes', {count: selectedNotes.length}))
                    ) {
                        await db.deleteNotes(selectedNotes.map(n => n.id), true)
                        setSelectedNotes([])
                        setSelectionMode(false)
                    }
                    break
                case 'restore':
                    if (await appConfirm(
                        t('unDeleteNotes', {count: selectedNotes.length}),
                        t('confirm.unDeleteNotes', {count: selectedNotes.length}))
                    ) {
                        for (const note of selectedNotes) {
                            note.deleted = null
                            await db.updateNote(note)
                        }
                        setSelectedNotes([])
                        setSelectionMode(false)
                    }
                    break
                case 'export':
                    const data = newExport(selectedNotes)
                    downloadAsFile('text/json', 'Notes.json', data.json())
                    break
                case 'moveToFolder':
                    setShowFolderTree(true)
                    break
            }
            setBulkAction('')
        })()
    }, [ bulkAction ]);

    useEffect(() => {
        if (selectedNotes.length && !selectionMode) {
            setSelectionMode(true)
        }

        if (selectedNotes.length > 0) {
            let buttons = []
            if (props.folderId !== SpecialFolders.Deleted) {
                buttons.push({
                    title: t('note.moveToFolder'),
                    active: false,
                    onClick: () => setBulkAction('moveToFolder'),
                    icon: <AppIcon icon={ AppIconName.Folder }/>
                })
                buttons.push({
                    title: t('export'),
                    active: false,
                    onClick: () => setBulkAction('export'),
                    icon: <AppIcon icon={ AppIconName.Download } regular/>
                })
                buttons.push({
                    title: t('delete'),
                    active: false,
                    onClick: () => setBulkAction('delete'),
                    icon: <AppIcon icon={ AppIconName.TrashCan } regular/>
                })
            } else {
                buttons.push({
                    title: t('restore'),
                    active: false,
                    onClick: () => setBulkAction('restore'),
                    icon: <AppIcon icon={ AppIconName.RotateLeft } rotate={ 90 }/>
                })
                buttons.push({
                    title: t('delete'),
                    active: false,
                    onClick: () => setBulkAction('deletePermanent'),
                    icon: <AppIcon icon={ AppIconName.TrashCan } regular/>
                })
            }

            activateBottomToolbar(
                <BottomToolbar buttons={buttons} showLabels/>
            )
        } else {
            deactivateBottomToolbar()
        }
    }, [ selectedNotes ]);

    useEffect(() => {
        if (selectionMode) {
            navigate('/edit-notes')
        } else {
            navigate('/')
        }
    }, [ selectionMode ]);

    useEffect(() => {
        if (location.pathname === '/' && selectionMode) {
            setSelectionMode(false)
            setSelectedNotes([])
        }
    }, [ location ])

    const onDrop = async (e: any) => {
        if (e.dataTransfer) {
            await importer.importFiles(e.dataTransfer.files)
        }
    }

    useEffect(() => {
        db.addEventListener('note', null, loadNotes)

        return () => {
            db.removeEventListener(loadNotes)
        }
    }, [ props.folderId ]);

    useEffect(() => {
        loadNotes()
    }, [ props.folderId ]);

    const sortNotes = (a: NoteObject, b: NoteObject) => {
        const dateA = a.meta.lastModified || a.meta.created
        const dateB = b.meta.lastModified || b.meta.created

        return dateA.getTime() < dateB.getTime() ? 1 : -1
    }

    const notesToShow = notes.filter(n => !!n.previewImage || n.encrypted).sort(sortNotes)

    const classNames = ['note-list']
    if (loading) {
        classNames.push('loading')
    }

    return (
        <FileDropper message="Drop file to import as notes" onDrop={onDrop}>
            <div className={ classNames.join(' ') }>
                <div className="row-selection-mode">
                    <div className="left">
                        <h1>{ props.title }</h1>
                        {notes.length && !selectionMode ? (
                            <div className="notes-count">
                                {t('notesCount', {count: notes.length})}
                            </div>
                        ) : null}

                        {selectionMode ? (
                            <div className="selection-notice">
                                {t('selectedNotesCount', {count: selectedNotes.length})}

                                <a href="#"
                                   className="app-button blank"
                                   onClick={exitSelectionMode}
                                   title={t('cancel')}>
                                    <AppIcon icon={AppIconName.Times}/>
                                </a>
                            </div>
                        ) : null}
                    </div>

                    <div className="right">
                        {selectionMode ? (
                            <></>
                        ) : (
                            <a href="#"
                               className="app-button blank"
                               onClick={startSelectionMode}
                               title={t('edit')}>
                                <AppIcon icon={AppIconName.Pencil}/>
                            </a>
                        )}
                    </div>
                </div>

                {notesToShow.length ? (
                    <ul>
                        {notesToShow.map(note => (
                            <li key={note.id}>
                                <NotePreview note={note}
                                             selected={selectedNotes.includes(note)}
                                             inSelectionMode={selectionMode}
                                             onSelect={() => onSelect(note)}/>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="no-notes-container">
                        {t('main.notelist.noNotesYet')}
                    </div>
                )}
            </div>

            { showFolderTree ? (
                <BottomContainer onClose={ () => setShowFolderTree(false) } width={ 400 }>
                    <FolderTree onClick={ onFolderClick }/>
                </BottomContainer>
            ) : null }
        </FileDropper>
    )
}