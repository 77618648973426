import AppModal, {ModalButtonAction} from "../AppModal";
import {createPortal} from "react-dom";
import {useTranslation} from "react-i18next";
import {createRef, useContext, useEffect, useState} from "react";
import AppContext from "../../context/AppContext";

export interface AppPromptProps {
    title: string
    message: string
    value: string
    onConfirm: (value: string) => void
    onClose: () => void
    forceCustom?: boolean
    forceNative?: boolean
}

const AppPrompt = (props: AppPromptProps) => {
    if (props.forceNative && props.forceCustom) {
        throw new Error('either forceNative of forceCustom may be set, not both together')
    }

    const { t } = useTranslation()

    const {
        isCordova,
    } = useContext(AppContext)

    const [ currentValue, setCurrentValue ] = useState(props.value)

    const inputRef = createRef<HTMLInputElement>()

    const showNative = !props.forceCustom && (isCordova || props.forceNative)

    const save = (e?: any) => {
        if (e) {
            e.preventDefault()
        }
        props.onConfirm(currentValue)
    }

    useEffect(() => {
        if (showNative) {
            const callback = (buttonIndex: number, value: string) => {
                if (buttonIndex === 1) {
                    props.onConfirm(value)
                    return
                }
                props.onClose()
            }

            // @ts-ignore
            navigator.notification.prompt(props.message, callback, props.title, undefined, props.value)
        }
    }, []);

    return showNative ? null : createPortal(
        <AppModal title={ props.title }
                  className="app-prompt"
                  width={ 400 }
                  onReady={ () => inputRef.current?.focus() }
                  buttons={[
                      { label: t('cancel'), action: ModalButtonAction.CLOSE, importance: 'secondary' },
                      { label: t('ok'), onClick: save },
                  ]}
                  onClose={ props.onClose }>
            <form onSubmit={ save }>
                <p>
                    { props.message }
                </p>
                <input type="text"
                       ref={ inputRef }
                       value={ currentValue }
                       onChange={ e => setCurrentValue(e.target.value) }/>
            </form>
        </AppModal>
    , document.body)
}

export default AppPrompt