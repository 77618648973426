import './MainActivity.scss'
import {useContext, useEffect, useState} from "react";
import AppContext from "../context/AppContext";
import MainToolbar from "../components/main-activity/MainToolbar";
import AppActivity from "../components/AppActivity";
import AppIcon, {AppIconName} from "../components/AppIcon";
import {useNavigate} from "react-router-dom";
import NoteList, {SpecialFolders} from "../components/main-activity/NoteList";
import useIndexedDB from "../database/IndexedDB";
import {useTranslation} from "react-i18next";
import SidebarContainer from "../components/SidebarContainer";
import FolderTree from "../components/FolderTree";
import NoteFolder from "../objects/NoteFolder";

export default () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const db = useIndexedDB()

    const defaultFolder = localStorage.getItem('notes-app-notelist-folder') as SpecialFolders | null || SpecialFolders.All

    const [ folderId, setFolderId ] = useState<SpecialFolders | string>(defaultFolder)
    const [ folder, setFolder ] = useState<NoteFolder | null>(null)
    const [ sidebarOpen, setSidebarOpen ] = useState(false)

    const {
        setCurrentNoteId,
    } = useContext(AppContext)

    useEffect(() => {
        if (folderId !== SpecialFolders.All && folderId !== SpecialFolders.Deleted) {
            ;(async () => {
                setFolder(await db.getFolder(folderId))
            })()
        }
    }, []);

    useEffect(() => setCurrentNoteId(null), []);

    const createNewNote = async () => {
        const note = await db.createNewNote({ folderId })
        navigate(`/note/${note.id}`)
    }

    const getHeadline = () => {
        if (folderId === SpecialFolders.All) {
            return t('main.notelist.allNotes')
        } else if (folderId === SpecialFolders.Deleted) {
            return t('recycleBin')
        }
        return folder ? folder.title : ''
    }

    const onSidebarClick = (specialFolder: SpecialFolders | string, e?: any) => {
        if (e) {
            e.preventDefault()
        }
        setFolderId(specialFolder)

        localStorage.setItem('notes-app-notelist-folder', specialFolder)
    }

    const onFolderSelected = (folder: NoteFolder) => {
        setFolderId(folder.id)
        setFolder(folder)

        localStorage.setItem('notes-app-notelist-folder', folder.id)
    }

    return (
        <AppActivity id={ 'main-activity' }
                     header={
                        <MainToolbar/>
                     }
                     fixedButton={ folderId !== SpecialFolders.Deleted ? {
                         title: t('createNewNote'),
                         content: <AppIcon icon={ AppIconName.Plus }/>,
                         onClick: createNewNote,
                     } : undefined }>
            <SidebarContainer
                onExpanded={ setSidebarOpen }
                sidebar={(
                    <>
                        <a href="#"
                           onClick={e => onSidebarClick(SpecialFolders.All, e)}
                           className={`sidebar-item ${folderId === SpecialFolders.All ? ' active' : ''}`}>
                            <div className="icon">
                                <AppIcon icon={AppIconName.NoteSticky} regular/>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    {t('main.notelist.allNotes')}
                                </div>
                            </div>
                        </a>
                        <a href="#"
                           onClick={e => onSidebarClick(SpecialFolders.Deleted, e)}
                           className={`sidebar-item ${folderId === SpecialFolders.Deleted ? ' active' : ''}`}>
                            <div className="icon">
                                <AppIcon icon={AppIconName.TrashCan} regular/>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    {t('recycleBin')}
                                </div>
                            </div>
                        </a>

                        { sidebarOpen ? (
                            <>
                                <hr/>

                                <FolderTree onClick={ onFolderSelected }
                                            selectedId={ folderId }
                                            regularIcons />
                            </>
                        ) : null}
                    </>
                )}
                content={(
                    <NoteList folderId={folderId} title={getHeadline()}/>
                )}/>
        </AppActivity>
    )
}