import {createContext, ReactElement, ReactNode} from "react";
import {SupportedLanguage} from "../api";
import {AppLanguage} from "../types";
import BottomToolbar from "../components/toolbar/BottomToolbar";

const AppContext = createContext<{
    apiBaseURL: string
    screenWidth: number
    screenHeight: number
    viewportHeight: number
    headerHeight: number
    setHeaderHeight: (height: number) => void
    currentNoteId: string | null
    setCurrentNoteId: (id: string | null) => void
    fullScreen: boolean
    requestFullScreen: () => void
    exitFullScreen: () => void
    darkMode: boolean
    setDarkMode: (enabled: boolean) => void
    supportedLanguages: { [displayLanguageCode: string]: SupportedLanguage[] }
    setSupportedLanguages: (displayLanguageCode: string, languages: SupportedLanguage[]) => void
    activeBottomToolbar: ReactElement<typeof BottomToolbar>|null,
    activateBottomToolbar: (toolbar: ReactElement<typeof BottomToolbar>|null) => void,
    deactivateBottomToolbar: () => void,
    appLanguages: AppLanguage[],
    appLanguage: string,
    setAppLanguage: (code: string) => void,
    checkScreenSize: () => void,
    showAlert: (title: string, message: string | ReactElement) => void
    hideAlert: () => void
    isCordova: boolean
    appAlert: (title: string, message: string) => Promise<void>
    appConfirm: (title: string, message: string) => Promise<boolean>
    appPrompt: (title: string, message: string, value?: string) => Promise<{ confirmed: boolean, value?: string }>
}>({
    apiBaseURL: '',
    screenWidth: -1,
    screenHeight: -1,
    viewportHeight: -1,
    headerHeight: 0,
    setHeaderHeight: () => null,
    currentNoteId: null,
    setCurrentNoteId: () => null,
    fullScreen: false,
    requestFullScreen: () => null,
    exitFullScreen: () => null,
    darkMode: false,
    setDarkMode: () => null,
    supportedLanguages: {},
    setSupportedLanguages: () => null,
    activeBottomToolbar: null,
    activateBottomToolbar: () => null,
    deactivateBottomToolbar: () => null,
    appLanguages: [],
    appLanguage: '',
    setAppLanguage: () => null,
    checkScreenSize: () => null,
    showAlert: () => null,
    hideAlert: () => null,
    isCordova: false,
    appAlert: () => Promise.resolve(),
    appConfirm: () => Promise.resolve(false),
    appPrompt: () => Promise.resolve({ confirmed: false }),
})

export default AppContext