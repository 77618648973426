import AppModal, {ModalButtonAction} from "../AppModal";
import {createPortal} from "react-dom";
import {useTranslation} from "react-i18next";
import {useContext, useEffect} from "react";
import AppContext from "../../context/AppContext";

export interface AppConfirmProps {
    title: string
    message: string
    onConfirm: () => void
    onClose: () => void
    forceNative?: boolean
}

const AppConfirm = (props: AppConfirmProps) => {
    const { t } = useTranslation()

    const {
        isCordova,
    } = useContext(AppContext)

    const showNative = isCordova || props.forceNative

    useEffect(() => {
        if (showNative) {
            const callback = (buttonIndex: number) => {
                if (buttonIndex === 1) {
                    props.onConfirm()
                    return
                }
                props.onClose()
            }

            // @ts-ignore
            navigator.notification.confirm(props.message, callback, props.title)
        }
    }, []);

    return showNative ? null : createPortal(
        <AppModal title={ props.title }
                  width={ 400 }
                  autoFocusButtonId={ 'ok' }
                  buttons={[
                      { label: t('cancel'), action: ModalButtonAction.CLOSE, importance: 'secondary' },
                      { label: t('ok'), onClick: props.onConfirm, id: 'ok' },
                  ]}
                  onClose={ props.onClose }>
            { props.message }
        </AppModal>
    , document.body)
}

export default AppConfirm