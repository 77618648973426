export default {
    language: 'Sprache',
    back: 'Zurück',
    newNoteName: 'Neue Notiz',
    ok: 'OK',
    cancel: 'Abbrechen',
    edit: 'Bearbeiten',
    undo: 'Rückgängig',
    redo: 'Wiederholen',
    savedColorWithNum: 'Gespeicherte Farbe {{num}}',
    lineWidth: 'Linienstärke',
    usePenPressure: 'Druckstärke berücksichtigen',
    fullScreen: 'Vollbild',
    exitFullScreen: 'Vollbild beenden',
    togglePageList: 'Seiten anzeigen/verbergen',
    remove: 'Löschen',
    read: 'Lesen',
    lockCanvas: 'Zeichenbereich sperren',
    unlockCanvas: 'Zeichenbereich entsperren',
    createNewNote: 'Neue Notiz erstellen',
    deleteNote: 'Notiz löschen',
    selectedNotesCount_zero: 'Keine Notizen gewählt',
    selectedNotesCount_one: '1 Notiz gewählt',
    selectedNotesCount: '{{count}} Notizen gewählt',
    notesCount_one: '1 Notiz',
    notesCount: '{{count}} Notizen',
    deleteNotes_one: 'Notiz löschen',
    deleteNotes: 'Notizen löschen',
    delete: 'Löschen',
    restore: 'Wiederherstellen',
    unDeleteNotes_one: 'Notiz wiederherstellen',
    unDeleteNotes: 'Notizen wiederherstellen',
    deletePaths: 'Pfade löschen',
    ttsPlay: 'Abspielen',
    recycleBin: 'Papierkorb',
    changeStyle: 'Stil ändern',
    deletePage: 'Seite löschen',
    add: 'Hinzufügen',
    image: 'Bild',
    page: 'Seite',
    functions: {
        text: 'Text [t]',
        draw: 'Zeichnen [d]',
        eraser: 'Radierer [e]',
        line: 'Linie [l]',
        select: 'Auswahl [s]',
        colorChange: 'Linienfarbe ändern [c]',
    },
    alert: {
        notesImported: {
            title: "Notizen importiert",
            message: "{{count}} Notizen erfolgreich importiert",
            message_one: "1 Notiz erfolgreich importiert"
        }
    },
    confirm: {
        deleteNote: 'Diese Notiz löschen?',
        deleteNotes_one: 'Die ausgewählte Notiz löschen?',
        deleteNotes: 'Die ausgewählten {{count}} Notizen löschen?',
        unDeleteNotes_one: 'Die ausgewählte Notiz wiederherstellen?',
        unDeleteNotes: 'Die ausgewählten {{count}} Notizen wiederherstellen?',
        deletePaths: 'Die ausgewählten Pfade wirklich löschen?',
        deletePage: 'Die ausgewählte Seite wirklich löschen?',
    },
    prompt: {
        noteTitle: {
            title: 'Titel',
            message: 'Neuer Titel dieser Notiz:',
        }
    },
    main: {
        notelist: {
            allNotes: 'Alle Notizen',
            noNotesYet: '(Noch) nichts zu sehen',
        },
        createFolder: 'Ordner erstellen',
    },
    note: {
        fingerDrawing: 'Fingerzeichnung',
        addNewPage: 'Neue Seite',
        print: 'Drucken',
        background: 'Hintergrund',
        translation: {
            serverRequestFailed: 'Serverabfrage fehlgeschlagen',
            detectedLanguage: 'Erkannte Sprache: {{lang}}',
            translate: 'Übersetzen',
        },
        import: 'Importieren',
        export: 'Exportieren',
        share: 'Teilen',
        download: 'Herunterladen',
        moveToFolder: 'Move to folder',
    },
}