import React, {createRef, useContext, useEffect, useRef, useState} from "react";

import './TextEditor.scss'
import NoteContext, {PointerFunction} from "context/NoteContext";
import useEvent from "react-use-event";
import {HistoryChangedEvent} from "../../types";
import {SettingsContext} from "../../context/SettingsContextProvider";
import useIndexedDB from "../../database/IndexedDB";
import {TextContentObject} from "../../objects/TextContentObject";
import AppContext from "../../context/AppContext";
import AppIcon, {AppIconName} from "../AppIcon";
import {useTranslation} from "react-i18next";
import BottomToolbar from "../toolbar/BottomToolbar";
import ColorSelectionField from "../toolbar/settings/ColorSelectionField";
import {NoteObject} from "../../objects/NoteObject";

type Props = {
    pageId: string
    onChange: () => void
}

export default ({ pageId, onChange }: Props) => {
    const { t } = useTranslation()

    const {
        pointerFunction,
    } = useContext(SettingsContext)

    const {
        note: maybeEmptyNote,
        updateNote,
    } = useContext(NoteContext)

    const {
        activateBottomToolbar,
        deactivateBottomToolbar,
        checkScreenSize,
    } = useContext(AppContext)

    const note = maybeEmptyNote as NoteObject

    // const recorder = useRecorder()

    const ref = createRef<HTMLParagraphElement>()

    const [ editable, setEditable ] = useState<boolean>(false)

    const [ content, setContent ] = useState<TextContentObject>(() => {
        return note.getTextContent(pageId)
    })

    const [ tempContent, setTempContent ] = useState<string>(content.content)
    const [ initialized, setInitialized ] = useState<boolean>(false)

    const [ showFontColorSelection, setShowFontColorSelection ] = useState<boolean>(false)
    const [ fontColor, setFontColor ] = useState<string>('#252525')

    const showFontColorSelectionRef = useRef(showFontColorSelection)
    showFontColorSelectionRef.current = showFontColorSelection

    const editableRef = useRef<boolean>(editable)
    editableRef.current = editable

    const tempContentRef = useRef<string>(tempContent)
    tempContentRef.current = tempContent

    useEvent<HistoryChangedEvent>('HistoryChanged', () => {
        const newContent = note.textContents.find(t => t.id === pageId)
        if (newContent && newContent.content !== content.content && newContent.content !== tempContent) {
            console.log('replace content', {old: content, new: newContent})
            setContent(newContent)
        }
    })

    useEffect(() => {
        if (ref.current) {
            setEditable(pointerFunction === PointerFunction.TEXT)
        }
    }, [ pointerFunction ]);

    useEffect(() => {
        if (ref.current) {
            ref.current.contentEditable = editable ? 'true' : 'false'

            if (!editable) {
                if (!initialized) {
                    setInitialized(true)
                    return
                }

                setContent({
                    ...content,
                    content: tempContent,
                })
                // recorder.updateText(pageId, tempContent)
            }
        }
    }, [ editable ]);

    const onKeyUp = (e: React.KeyboardEvent) => {
        if (ref.current) {
            const c = ref.current.innerHTML
            setTempContent(c)
        }
    }

    useEffect(() => {
        (async () => {
            if (tempContent !== content.content) {
                note.updateTextContent({
                    ...content,
                    content: tempContentRef.current || '',
                })

                await updateNote(note)

                onChange()
            }
        })()
    }, [ tempContent ]);

    useEffect(() => {
        if (ref.current && editable) {
            ref.current.focus()

            // move selection to the end
            const range = document.createRange()
            range.selectNodeContents(ref.current)
            range.collapse(false)

            const selection = window.getSelection()
            if (selection) {
                selection.removeAllRanges()
                selection.addRange(range)
            }

            const container = ref.current

            const buttons = [
                { title: 'textedit.bold', icon: AppIconName.Bold, action: () => execCommand(container, 'bold') },
                { title: 'textedit.italic', icon: AppIconName.Italic, action: () => execCommand(container, 'italic') },
                { title: 'textedit.underline', icon: AppIconName.Underline, action: () => execCommand(container, 'underline') },
                { title: 'textedit.strikethrough', icon: AppIconName.Strikethrough, action: () => execCommand(container, 'strikethrough') },

                { title: 'textedit.align.left', icon: AppIconName.AlignLeft, action: () => execCommand(container, 'justifyLeft') },
                { title: 'textedit.align.center', icon: AppIconName.AlignCenter, action: () => execCommand(container, 'justifyCenter') },
                { title: 'textedit.align.right', icon: AppIconName.AlignRight, action: () => execCommand(container, 'justifyRight') },
                { title: 'textedit.align.justify', icon: AppIconName.AlignJustify, action: () => execCommand(container, 'justifyFull') },
            ]

            const toolbarButtons = buttons.map(button => ({
                title: button.title,
                icon: <AppIcon icon={ button.icon } />,
                onClick: button.action,
                active: false,
            }))

            const onColorChange = (color: string) => {
                setFontColor(color)
            }

            activateBottomToolbar(
                <BottomToolbar buttons={ [
                    ...toolbarButtons,

                    { 
                        title: 'Color',
                        icon: <AppIcon icon={ AppIconName.Font }/>,
                        onClick: () => null,
                        active: false,
                        settingsContainer: (
                            <ColorSelectionField value={ fontColor }
                                                 onChange={ onColorChange }/>
                        )
                    }
                    
                ]} align={ 'left' }/>
            )
        } else {
            deactivateBottomToolbar()
        }
    }, [ editable, fontColor ]);

    useEffect(() => {
        if (ref.current) {
            console.log('force color', fontColor, ref.current)
            execCommand(ref.current, 'forceColor', fontColor)
        }
    }, [ fontColor ]);

    useEffect(() => {
        if (ref.current) {
            const onFocus = () => {
                checkScreenSize()
            }
            const onBlur = () => {
                checkScreenSize()
            }

            // ref.current.addEventListener('focus', onFocus)
            // ref.current.addEventListener('blur', onBlur)
            //
            // return () => {
            //     if (ref.current) {
            //         ref.current.removeEventListener('focus', onFocus)
            //         ref.current.removeEventListener('blur', onBlur)
            //     }
            // }
        }
    }, [ref]);

    const execCommand = (container: HTMLParagraphElement, command: string, value: any = null) => {
        // @ts-ignore
        document.execCommand('styleWithCSS', false, true);
        document.execCommand(command, false, value)
        console.log('SET', value)
        setTempContent(container.innerHTML)
    }

    const classNames = ['note-page__edittext']
    if (editable) {
        classNames.push('editable')
    }

    return (
        <div className={ classNames.join(' ') }>
            <p ref={ ref }
               style={ content.style }
               dangerouslySetInnerHTML={{ __html: content.content }}
               onKeyUp={ onKeyUp }
               id={`edit-text-${pageId}`}/>
        </div>
    )
}