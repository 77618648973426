export type NoteFolderData = {
    id: string
    parentId: string | null
    order: number
    title: string
    children?: NoteFolder[]
}

export default class NoteFolder {
    id: string
    parentId: string | null
    order: number
    title: string
    children: NoteFolder[] = []

    constructor(data: NoteFolderData) {
        this.id = data.id
        this.parentId = data.parentId
        this.order = data.order
        this.title = data.title

        if (data.children) {
            this.children = data.children.map(childData => new NoteFolder(childData));
        }
    }
}