import React, {useContext, useEffect, useRef, useState} from "react";

import './NotePage.scss'
import PointerIndicator from "./note-activity/PointerIndicator";
import SelectionIndicator from "./note-activity/SelectionIndicator";
import SelectionBox from "./note-activity/SelectionBox";
import NoteContext, {PointerFunction} from "context/NoteContext";
import {AddToHistoryEvent, Box, CancelAllActionsEvent, PanEvent, Point, RequestCenterEvent, Transform} from "types";
import useEvent from "react-use-event";
import TextEditor from "./note-activity/TextEditor";
import useCanvasHandler from "../lib/canvas/useCanvasHandler";
import {NoteObject} from "../objects/NoteObject";
import {SettingsContext} from "../context/SettingsContextProvider";
import {createPortal} from "react-dom";
import BottomContainer from "./BottomContainer";
import ColorSelectionField from "./toolbar/settings/ColorSelectionField";
import RangeSelector from "./RangeSelector";
import NoteImageObject from "../objects/NoteImageObject";
import {randomUUID} from "../lib";
import FileDropper from "./FileDropper";
import {useTranslation} from "react-i18next";

let lastPosition: Point | null = null

export type NotePageProps = {
    note: NoteObject
    pageId: string
    inGesture: boolean
    onChange: () => void
}

export default (props: NotePageProps) => {
    const { t } = useTranslation()

    const {
        lineWidth,
        lineColor,
        lineColorForChange,
        lineColorForChangeRadius,
        pressureEnabled,
        pointerFunction,
        eraserRadius,
        canvasLocked,
        allowFinger,
    } = useContext(SettingsContext)

    const {
        initialized,
        updateNote,
        addImages,
        pointerFunctionOverride,
        setPointerFunctionOverride,
        translate,
        scale,
        setShowTranslator,
        inGesture,
    } = useContext(NoteContext)

    const page = props.note.getPage(props.pageId)

    const { canvas, ctx, renderer, setHandlerCallbacks } = useCanvasHandler(props.note, props.pageId)

    useEffect(() => {
        renderer.render(props.note)
    }, [ props.note ]);

    const eraserRadiusRef = useRef(eraserRadius)
    eraserRadiusRef.current = eraserRadius

    const inGestureRef = useRef<boolean>(props.inGesture)
    inGestureRef.current = props.inGesture

    const scaleRef = useRef<number>(scale)
    scaleRef.current = scale

    const dispatchPanEvent = useEvent<PanEvent>('Pan')
    const dispatchRequestCenterEvent = useEvent<RequestCenterEvent>('RequestCenter')

    const [ cursorPosition, setCursorPosition ] = useState<Point | null>(null)
    const [ pointerDown, setPointerDown ] = useState<boolean>(false)
    const [ pointerType, setPointerType ] = useState<string | null>(null)
    const [ selectionArea, setSelectionArea ] = useState<Box | null>(null)
    const [ selectionBox, setSelectionBox ] = useState<Box | null>(null)
    const [ selectionBoxShown, setSelectionBoxShown ] = useState<boolean>(false)
    const [ showStyleChange, setShowStyleChange ] = useState<boolean>(false)
    const [ currentTransform ] = useState<Transform>({ x: 0, y: 0, scale: 1})

    const [ styleChangeLineWidth, setStyleChangeLineWidth ] = useState(1)
    const [ styleChangeColor, setStyleChangeColor ] = useState('#252525')

    const transformRef = useRef<Transform>({ x: 0, y: 0, scale: 1})
    transformRef.current = currentTransform

    const pointerTypeRef = useRef<string | null>()
    pointerTypeRef.current = pointerType

    const pointerDownRef = useRef<boolean>()
    pointerDownRef.current = pointerDown

    const lineColorRef = useRef<string>(lineColor)
    lineColorRef.current = lineColor

    const currentFunctionRef = useRef<PointerFunction>(pointerFunctionOverride || pointerFunction)
    currentFunctionRef.current = pointerFunctionOverride || pointerFunction

    const pressureEnabledRef = useRef<boolean>(pressureEnabled)
    pressureEnabledRef.current = pressureEnabled

    const allowFingerRef = useRef<boolean>(allowFinger)
    allowFingerRef.current = allowFinger

    const lineWidthRef = useRef<number>(lineWidth)
    lineWidthRef.current = lineWidth

    const lineColorForChangeRef = useRef<string>(lineColorForChange)
    lineColorForChangeRef.current = lineColorForChange

    const noteRef = useRef(props.note)
    noteRef.current = props.note

    const onPointerMove = (e: React.PointerEvent<HTMLCanvasElement>) => {
        if (e.pointerType === 'touch' && !allowFingerRef.current) {
            console.log('prevent finger')
            return
        }

        e.preventDefault()

        if (inGestureRef.current) {
            return
        }

        let currentLineWidth = lineWidthRef.current

        let forceErase = false
        if (e.pointerType === 'pen') {
            if (e.button === 0) {
                forceErase = true
            }
            if (pressureEnabledRef.current && e.pressure) {
                currentLineWidth *= e.pressure
            }
        }

        if (forceErase) {
            setPointerFunctionOverride(PointerFunction.ERASE)
        }

        const position = recalculatePositionWithZoom({
            x: e.pageX,
            y: e.pageY,
        })

        setCursorPosition(position)

        if (pointerDownRef.current) {
            if (lastPosition) {
                switch (currentFunctionRef.current) {
                    case PointerFunction.DRAW:
                    case PointerFunction.Line:
                        renderer.continuePath(position.x, position.y, currentLineWidth)
                        break
                    case PointerFunction.ERASE:
                        if (renderer.checkDelete(position.x, position.y, eraserRadiusRef.current / 2)) {
                            renderer.render(noteRef.current)
                        }
                        break
                    case PointerFunction.ColorChange:
                        renderer.checkColorChange(position.x, position.y, lineColorForChangeRadius / 2, lineColorForChangeRef.current)
                        break
                    case PointerFunction.PAN:
                        if (!canvasLocked) {
                            renderer.setCursor('move')
                            dispatchPanEvent({
                                movement: {
                                    x: e.movementX,
                                    y: e.movementY,
                                },
                            })
                        }
                        break
                }
            }

            lastPosition = position
        }
    }

    const onPointerDown = (e: React.PointerEvent<HTMLCanvasElement>) => {
        if (e.pointerType === 'touch' && !allowFingerRef.current) {
            return
        }

        setPointerType(e.pointerType)
        setSelectionBox(null)
        renderer.clearSelection()

        // not left mouse button
        if (e.pointerType === 'mouse') {
            if (e.button > 1) {
                return
            }
        }

        e.preventDefault()

        const position = recalculatePositionWithZoom({
            x: e.pageX,
            y: e.pageY,
        })

        setPointerDown(true)
        setCursorPosition(position)

        let hasOverride = false
        if (e.pointerType === 'mouse' && e.button === 1) {
            setPointerFunctionOverride(PointerFunction.PAN)
            hasOverride = true
        }

        if ([PointerFunction.DRAW, PointerFunction.Line].includes(currentFunctionRef.current) && !hasOverride) {
            lastPosition = position
            renderer.startPath(position.x, position.y, lineWidthRef.current, lineColorRef.current, currentFunctionRef.current === PointerFunction.Line ? 'line' : 'path')
        }
    }

    const onPointerUp = (e: React.PointerEvent<HTMLCanvasElement>) => {
        if (e.pointerType === 'touch' && !allowFingerRef.current) {
            return
        }

        if (renderer.getCursor() === 'move') {
            dispatchRequestCenterEvent({})
        }

        e.preventDefault()

        renderer.resetCursor()

        setPointerDown(false)
        setPointerFunctionOverride(null)

        if (currentFunctionRef.current === PointerFunction.ERASE) {
            setCursorPosition({ x: -1, y: -1 })
        }

        lastPosition = null

        renderer.closePath()
        renderer.persistChanges()
    }

    const onPointerLeave = (e: React.PointerEvent<HTMLCanvasElement>) => {
        if (e.pointerType === 'touch' && !allowFinger) {
            return
        }

        e.preventDefault()
        e.stopPropagation()

        renderer.resetCursor()

        setCursorPosition(null)
        setPointerDown(false)
    }

    useEffect(() => {
        renderer.render(props.note)

        setHandlerCallbacks({
            onPointerMove,
            onPointerDown,
            onPointerUp,
            onPointerLeave,
        })
    }, []);

    const recalculatePositionWithZoom = (p: Point): Point => {
        const box = renderer.getCanvasBox()
        if (!box) {
            return p
        }

        const scale = scaleRef.current

        const canvasGlobalTop = box.top - (translate.y * scale)
        const pointerGlobalTop = p.y - (translate.y * scale)

        return {
            x: (p.x - box.left) / scale,
            y: (pointerGlobalTop - canvasGlobalTop) / scale,
        }
    }

    const onSelectionStart = () => {
        setSelectionBox(null)
        setSelectionBoxShown(false)
        setShowStyleChange(false)
    }

    const onSelectionChange = (box: Box) => {
        const selectionBox = renderer.checkSelect(box)

        if (selectionBox.x >= 0 && selectionBox.y >= 0) {
            setSelectionBox(selectionBox)
        }
    }

    const onSelectionComplete = () => {
        if (selectionBox) {
            setSelectionBoxShown(true)
        }
    }

    const onSelectionMove = (movement: Point) => {
        renderer.moveSelection(movement)
    }

    const onSelectionScale = (pivotX: number, pivotY: number, factorX: number, factorY: number) => {
        renderer.scaleSelection(pivotX, pivotY, factorX, factorY)
    }

    const onSelectionChanged = () => {
        renderer.persistChanges()
    }

    const onRemoveSelectedElements = () => {
        renderer.removeSelection()
        setSelectionBox(null)
    }

    const onChangePathStyle = () => {
        setShowStyleChange(true)
    }

    const onReadClick = () => {
        const image = renderer.imageFromSelection()
        setShowTranslator(image)
    }

    const onCancelStyleChange = () => {
        setStyleChangeLineWidth(1)
        setStyleChangeColor('#252525')
        setShowStyleChange(false)
    }

    const onStyleChange = () => {
        renderer.changeSelectedPaths(styleChangeLineWidth, styleChangeColor)
    }

    useEffect(() => {
        setSelectionBoxShown(false)
        setShowStyleChange(false)
    }, [ pointerFunction ]);

    useEffect(() => {
        if (selectionArea) {
            if (!selectionArea.width || !selectionArea.height) {
                setSelectionArea(null)
            }
        }
    }, [ selectionArea ]);

    useEffect(() => {
        if (selectionBoxShown) {
            document.body.style.touchAction = "none"
        } else {
            document.body.style.touchAction = "auto"
            setShowStyleChange(false)
        }
    }, [ selectionBoxShown ]);

    useEffect(() => {
        if (inGesture) {
            renderer.cancelCurrentPath()
        }
    }, [ inGesture ]);

    const onDrop = async (e: DragEvent) => {
        if (e.dataTransfer) {
            let x = 20
            let y = 20

            const images: NoteImageObject[] = []
            for (const file of Array.from(e.dataTransfer.files)) {
                const { dataUri, width, height } = await new Promise<{ dataUri: string, width: number, height: number }>(resolve => {
                    const reader = new FileReader()
                    reader.onload = () => {
                        if (!reader.result) {
                            return
                        }
                        const img = new Image()
                        img.onload = () => {
                            resolve({ dataUri: reader.result as string, width: img.width, height: img.height })
                        }
                        img.src = reader.result as string
                    }
                    reader.readAsDataURL(file)
                })

                images.push({
                    id: randomUUID(),
                    pageId: page.id,
                    dataUri,
                    x,
                    y,
                    width,
                    height,
                    scale: 1,
                    rotate: 0,
                })

                x += 20
                y += 20
            }

            if (images.length) {
                addImages(images)
                props.onChange()
            }
        }
    }

    useEvent<CancelAllActionsEvent>('CancelAllActions', () => {
        setSelectionBox(null)
        renderer.clearSelection()
    })

    useEvent<AddToHistoryEvent>('AddToHistory', () => renderer.persistChanges())

    const selectedItemTypes = renderer.getSelectedItemTypes()

    const classNames = ['note-page']
    if (!initialized) {
        classNames.push('loading')
    }

    return (
        <div className={classNames.join(' ')} data-page-id={page.id}>
            <FileDropper message="Drop file to add it to your note!" onDrop={ onDrop }>
                <div className="note-page__canvas-wrap">
                    <>
                        {canvas}

                        <TextEditor pageId={page.id} onChange={ props.onChange }/>

                        { pointerFunction === PointerFunction.ERASE || pointerFunctionOverride === PointerFunction.ERASE ? (
                            <PointerIndicator position={cursorPosition}
                                              radius={ eraserRadius }/>
                        ) : null }

                        { pointerFunction === PointerFunction.ColorChange || pointerFunctionOverride === PointerFunction.ColorChange ? (
                            <PointerIndicator position={cursorPosition}
                                              color={ lineColorForChange }
                                              radius={ lineColorForChangeRadius }/>
                        ) : null }

                        <SelectionIndicator ctx={ctx || null}
                                            cursorPosition={cursorPosition}
                                            onStart={onSelectionStart}
                                            onSelect={onSelectionChange}
                                            onComplete={onSelectionComplete}
                                            pointerDown={pointerDown}/>

                        {selectionBox && selectionBoxShown ? createPortal(
                            <SelectionBox box={selectionBox as Box}
                                          scale={scale}
                                          translate={translate}
                                          selectedItemTypes={selectedItemTypes}
                                          onRemove={onRemoveSelectedElements}
                                          onChangeStyleClick={onChangePathStyle}
                                          onChanged={onSelectionChanged}
                                          onScale={onSelectionScale}
                                          onReadClick={onReadClick}
                                          onMove={onSelectionMove}/>
                            , document.body) : null}
                    </>
                </div>
            </FileDropper>

            { showStyleChange ? createPortal(
                <BottomContainer onClose={ onCancelStyleChange } width={ 260 }>
                    <ColorSelectionField value={ styleChangeColor } onChange={ setStyleChangeColor }/>
                    <RangeSelector value={ styleChangeLineWidth } max={ 10 } onChange={ setStyleChangeLineWidth }/>
                    <button onClick={ onStyleChange }>{ t('apply') }</button>
                    <button onClick={ onCancelStyleChange }>{ t('cancel') }</button>
                </BottomContainer>
            , document.body) : null }

            {/*<pre style={{position: 'fixed', left: '0px', top: '0', background: '#fff'}}>*/}
            {/*    {JSON.stringify({showStyleChange})}*/}
            {/*</pre>*/}
        </div>
    )
}
