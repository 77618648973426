import {createContext, ReactNode, useContext, useEffect, useRef, useState} from "react";
import {NoteObject} from "../../objects/NoteObject";
import {DBChangeEvent, HistoryChangedEvent} from "../../types";
import useIndexedDB from "../../database/IndexedDB";
import {deepCopy} from "../index";
import NoteContext from "../../context/NoteContext";
import useEvent from "react-use-event";

const HistoryContext = createContext<{
    noteHistory: HistoryEntry[], setNoteHistory: (entries: HistoryEntry[]) => void
    pointer: number, setPointer: (pointer: number) => void
}>({
    noteHistory: [],
    setNoteHistory: () => null,
    pointer: 0,
    setPointer: () => null,
})

type HistoryEntry = {
    timestamp: number
    note: NoteObject
}

export const HistoryContextProvider = ({ children }: { children: ReactNode }) => {
    const [ noteHistory, setNoteHistory ] = useState<HistoryEntry[]>([])
    const [ pointer, setPointer ] = useState<number>(0)

    const ctx = {
        noteHistory, setNoteHistory,
        pointer, setPointer,
    }

    return <HistoryContext.Provider value={ ctx }>
        { children }
    </HistoryContext.Provider>
}

export const useHistory = () => {
    const db = useIndexedDB()

    const dispatchChangeEvent = useEvent<HistoryChangedEvent>('HistoryChanged')

    const {
        note,
    } = useContext(NoteContext)

    const [initialized, setInitialized] = useState(false)

    const {
        noteHistory, setNoteHistory,
        pointer, setPointer,
    } = useContext(HistoryContext)

    const historyRef = useRef(noteHistory)
    historyRef.current = noteHistory

    const pointerRef = useRef(pointer)
    pointerRef.current = pointer

    useEffect(() => {
        if (!initialized && note) {
            addEntry(note)
            init()
        }
    }, [note]);

    const addEntry = (note: NoteObject) => {
        let currentHistory = historyRef.current
        if (pointerRef.current < currentHistory.length - 1) {
            currentHistory = currentHistory.slice(0, pointerRef.current + 1)
        }

        setPointer(currentHistory.length)
        setNoteHistory([
            ...currentHistory,
            {
                timestamp: note.meta.lastModified.getTime(),
                note: deepCopy(note),
            }
        ])
    }

    const init = () => {
        setInitialized(true)

        const onChange = (event: DBChangeEvent) => {
            const note = event.object as NoteObject
            if (!note.meta.lastModified) {
                return
            }

            const timestamp = note.meta.lastModified.getTime()

            for (const entry of historyRef.current) {
                if (entry.timestamp === timestamp) {
                    console.log('ignore already known state')
                    return
                }
            }

            addEntry(note)
        }

        db.addEventListener('note', ['create', 'update'], onChange)

        return () => {
            db.removeEventListener(onChange)
        }
    }

    const back = async () => {
        const newPointer = pointerRef.current - 1

        if (newPointer >= 0 && typeof historyRef.current[newPointer] !== 'undefined') {
            await db.updateNote(new NoteObject(historyRef.current[newPointer].note), true)
            setPointer(newPointer)
            dispatchChangeEvent({})
        } else {
            console.log('back not possible', newPointer, historyRef.current.length)
        }
    }

    const forward = async () => {
        const newPointer = pointerRef.current + 1

        if (typeof historyRef.current[newPointer] !== 'undefined') {
            await db.updateNote(new NoteObject(deepCopy(historyRef.current[newPointer].note)), true)
            setPointer(newPointer)
            dispatchChangeEvent({})
        } else {
            console.log('back not possible', newPointer, historyRef.current.length)
        }
    }
    // console.log('history length', historyRef.current.length, 'pointer', pointer)

    return {
        back,
        forward,
        hasPrevious: pointer > 0,
        hasNext: pointer < noteHistory.length - 1,
    }
}