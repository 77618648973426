import AppModal from "../AppModal";
import {createPortal} from "react-dom";
import {useTranslation} from "react-i18next";
import {ReactNode, useContext, useEffect} from "react";
import AppContext from "../../context/AppContext";

type Props = {
    title: string
    message: string | ReactNode
    onConfirm: () => void
    forceNative?: boolean
}

const AppAlert = (props: Props) => {
    const { t } = useTranslation()

    const {
        isCordova,
    } = useContext(AppContext)

    const showNative = isCordova || props.forceNative

    useEffect(() => {
        if (showNative) {
            // @ts-ignore
            navigator.notification.alert(props.message, props.onConfirm, props.title, t('ok'))
        }
    }, []);

    return showNative ? null : createPortal(
        <AppModal title={ props.title }
                  width={ 400 }
                  autoFocusButtonId={ 'ok' }
                  buttons={[
                      { label: t('ok'), onClick: props.onConfirm, id: 'ok' },
                  ]}
                  onClose={ props.onConfirm }>
            { props.message }
        </AppModal>
    , document.body)
}

export default AppAlert