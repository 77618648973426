import NoteActivity from "./activities/NoteActivity";
import AppContext from "./context/AppContext";
import React, {ReactElement, ReactNode, useEffect, useState} from "react";

import './App.scss'
import MainActivity from "./activities/MainActivity";
import {SupportedLanguage} from "./api";
import {HashRouter, Route, Routes, useNavigate} from "react-router-dom";
import SettingsContextProvider from "./context/SettingsContextProvider";
import useIndexedDB, {IndexedDBProvider} from "./database/IndexedDB";
import {useTranslation} from "react-i18next";
import BottomToolbar from "./components/toolbar/BottomToolbar";
import {HistoryContextProvider} from "./lib/history";
import AppAlert from "./components/note-activity/AppAlert";
import AppConfirm, {AppConfirmProps} from "./components/note-activity/AppConfirm";
import AppPrompt, {AppPromptProps} from "./components/note-activity/AppPrompt";

export default () => {
    const { i18n } = useTranslation()

    const indexedDB = useIndexedDB()

    const [ screenWidth, setScreenWidth ] = useState<number>(-1)
    const [ screenHeight, setScreenHeight ] = useState<number>(-1)
    const [ viewportHeight, setViewportHeight ] = useState<number>(-1)
    const [ fullScreen, setFullScreen ] = useState<boolean>(false)
    const [ currentNoteId, setCurrentNoteId ] = useState<string | null>(null)
    const [ headerHeight, setHeaderHeight ] = useState<number>(0)
    const [ darkMode, setDarkMode ] = useState<boolean>(false)
    const [ supportedLanguages, setSupportedLanguages ] = useState<{ [displayLanguageCode: string]: SupportedLanguage[] }>({})
    const [ activeBottomToolbar, setActiveBottomToolbar ] = useState<ReactElement<typeof BottomToolbar>|null>(null)
    const [ appLanguage, setAppLanguage ] = useState<string>(localStorage.getItem('notes-app-language') || 'en')

    const [ defaultAlert, setDefaultAlert ] = useState<{ title: string, message: string | ReactElement, onConfirm?: () => void } | null>(null)
    const [ defaultConfirm, setDefaultConfirm ] = useState<AppConfirmProps | null>(null)
    const [ defaultPrompt, setDefaultPrompt ] = useState<AppPromptProps | null>(null)

    useEffect(() => {
        if (appLanguage) {
            localStorage.setItem('notes-app-language', appLanguage)
            i18n.changeLanguage(appLanguage)
        }
    }, [ appLanguage ]);

    const requestFullScreen = () => {
        const element = document.body
        if(element.requestFullscreen) {
            element.requestFullscreen();
            setFullScreen(true)
            // @ts-ignore
        } else if(element.webkitRequestFullscreen) {  // iOS Safari
            // @ts-ignore
            element.webkitRequestFullscreen();
            setFullScreen(true)
        }
    }

    const exitFullScreen = () => {
        try {
            if(document.exitFullscreen) {
                document.exitFullscreen();
                // @ts-ignore
            } else if(document.webkitExitFullscreen) {
                // @ts-ignore
                document.webkitExitFullscreen();
            }
        } catch (e) {
            console.warn('error exiting fullscreen', e)
        }
        setFullScreen(false)
    }

    const activateBottomToolbar = (toolbar: ReactElement<typeof BottomToolbar>) => {
        setActiveBottomToolbar(toolbar)
    }

    const deactivateBottomToolbar = () => {
        setActiveBottomToolbar(null)
    }

    const checkScreenSize = () => {
        if (window.visualViewport) {
            const height = window.visualViewport.height
            setViewportHeight(height)
            document.documentElement.style.setProperty('--viewport-height', `${height}px`)
        }

        document.documentElement.style.setProperty('--screen-width', `${window.innerWidth}px`)
        document.documentElement.style.setProperty('--screen-height', `${window.innerHeight}px`)

        setScreenWidth(window.innerWidth)
        setScreenHeight(window.innerHeight)
    }

    const onAppAlertConfirm = () => {
        if (defaultAlert && defaultAlert.onConfirm) {
            defaultAlert.onConfirm()
        }
        setDefaultAlert(null)
    }

    const onAppConfirmResult = (confirmed: boolean) => {
        if (confirmed) {
            if (defaultConfirm && defaultConfirm.onConfirm) {
                defaultConfirm.onConfirm()
            }
        } else {
            if (defaultConfirm && defaultConfirm.onClose) {
                defaultConfirm.onClose()
            }
        }
        setDefaultConfirm(null)
    }

    const onAppPromptResult = (confirmed: boolean, value?: string) => {
        if (confirmed) {
            if (defaultPrompt && value) {
                defaultPrompt.onConfirm(value)
            }
        } else {
            if (defaultConfirm && defaultConfirm.onClose) {
                defaultConfirm.onClose()
            }
        }
        setDefaultPrompt(null)
    }

    const appAlert = async (title: string, message: string) => {
        return new Promise<void>(resolve => {
            setDefaultAlert({ title, message, onConfirm: () => {
                resolve()
            }})
        })
    }

    const appConfirm = async (title: string, message: string) => {
        return new Promise<boolean>(resolve => {
            setDefaultConfirm({
                title,
                message,
                onConfirm: () => resolve(true),
                onClose: () => resolve(false),
            })
        })
    }

    const appPrompt = async (title: string, message: string, value?: string) => {
        return new Promise<{ confirmed: boolean, value?: string }>(resolve => {
            setDefaultPrompt({
                title,
                message,
                value: value || '',
                onConfirm: value => resolve({ confirmed: true, value }),
                onClose: () => resolve({ confirmed: false }),
            })
        })
    }

    const isCordova = !!window.cordova

    const context = {
        apiBaseURL: 'https://sfnotes.app/api',
        screenWidth,
        screenHeight,
        viewportHeight,
        headerHeight,
        setHeaderHeight,
        currentNoteId,
        setCurrentNoteId,
        fullScreen,
        requestFullScreen,
        exitFullScreen,
        darkMode,
        activeBottomToolbar,
        checkScreenSize,
        activateBottomToolbar: activateBottomToolbar as (toolbar: ReactElement<typeof BottomToolbar>|null) => void,
        deactivateBottomToolbar,
        isCordova,
        showAlert: (title: string, message: string | ReactElement) => {
            setDefaultAlert({ title, message })
        },
        hideAlert: () => {
            setDefaultAlert(null)
        },
        appAlert,
        appConfirm,
        appPrompt,
        appLanguages: [
            {
                code: 'en',
                displayName: {
                    en: 'English',
                    de: 'Englisch',
                    ru: 'Английский',
                }
            },
            {
                code: 'de',
                displayName: {
                    en: 'German',
                    de: 'Deutsch',
                    ru: 'Немецкий',
                }
            },
            // {
            //     code: 'ru',
            //     displayName: {
            //         en: 'Russian',
            //         de: 'Russisch',
            //         ru: 'Русский',
            //     }
            // },
        ],
        appLanguage,
        setAppLanguage,
        setDarkMode: (enabled: boolean) => {
            setDarkMode(enabled)
            localStorage.setItem('dark-mode', enabled ? '1' : '0')
        },
        supportedLanguages,
        setSupportedLanguages: (displayLanguageCode: string, languages: SupportedLanguage[]) => {
            setSupportedLanguages({
                ...supportedLanguages,
                [displayLanguageCode]: languages,
            })
        }
    }

    useEffect(() => {
        if (darkMode) {
            document.body.classList.add('dark')
        } else {
            document.body.classList.remove('dark')
        }
    }, [ darkMode ]);

    useEffect(() => {
        console.log({ viewportHeight, screenWidth, screenHeight })
    }, [viewportHeight, screenWidth, screenHeight]);

    useEffect(() => {
        checkScreenSize()
    }, [ fullScreen ]);

    useEffect(() => {
        if (isCordova) {
            const { store, ProductType, Platform } = window.CdvPurchase

            store.register([{
                type: ProductType.PAID_SUBSCRIPTION,
                id: 'onlineservices',
                platform: Platform.TEST,
            }])
            store.when()
                .productUpdated(() => {
                    console.log("PRODUCT UPDATED")
                })
                .approved(() => {
                    console.log('PURCHASE FINISHED')
                })
            store.initialize([Platform.TEST])

            const product = store.get('onlineservices', Platform.TEST);
            console.log('PRODUCT', product)
        }

        document.body.addEventListener('fullscreenchange', checkScreenSize)

        if (localStorage.getItem('dark-mode') === '1') {
            setDarkMode(true)
        }

        checkScreenSize()
        window.addEventListener('resize', checkScreenSize)

        window.visualViewport?.addEventListener('resize', event => console.log('visuel port change', event.target));


        return () => {
            window.removeEventListener('resize', checkScreenSize)
        }
    }, []);

    return (
        <>
            { !indexedDB.initialized ? (
                <div>
                    Loading&hellip;
                </div>
            ) : (
                <AppContext.Provider value={ context }>
                    <SettingsContextProvider>
                        <HistoryContextProvider>
                            <HashRouter>
                                <Routes>
                                    <Route path={'/'} element={ <MainActivity/> }/>
                                    <Route path={'/edit-notes'} element={ <MainActivity/> }/>
                                    <Route path={`/note/:noteId`} element={ <NoteActivity/> }/>
                                    <Route path={`/share/:noteId`} element={ <NoteActivity fromShare/> }/>
                                </Routes>
                            </HashRouter>
                        </HistoryContextProvider>
                    </SettingsContextProvider>
                </AppContext.Provider>
            )}

            { defaultAlert ? (
                <AppAlert title={ defaultAlert.title }
                          message={ defaultAlert.message }
                          forceNative={ isCordova }
                          onConfirm={ onAppAlertConfirm }/>
            ) : null }

            { defaultConfirm ? (
                <AppConfirm title={ defaultConfirm.title }
                          message={ defaultConfirm.message }
                          onConfirm={ () => onAppConfirmResult(true) }
                          forceNative={ isCordova }
                          onClose={ () => onAppConfirmResult(false) }/>
            ) : null }

            { defaultPrompt ? (
                <AppPrompt title={ defaultPrompt.title }
                          message={ defaultPrompt.message }
                          value={ defaultPrompt.value }
                          onConfirm={ (value: string) => onAppPromptResult(true, value) }
                          forceCustom
                          onClose={ () => onAppPromptResult(false) }/>
            ) : null }
        </>
    )
}
