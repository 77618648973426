import './FolderTree.scss'
import {ReactNode, useContext, useEffect, useState} from "react";
import NoteFolder from "../objects/NoteFolder";
import AppIcon, {AppIconName} from "./AppIcon";
import {useTranslation} from "react-i18next";
import AppContext from "../context/AppContext";
import useIndexedDB from "../database/IndexedDB";

interface FolderTreeProps {
    selectedId?: string
    regularIcons?: boolean
    onClick?: (folder: NoteFolder) => void
}

const FolderTree = (props: FolderTreeProps) => {
    const { t } = useTranslation()

    const db = useIndexedDB()

    const {
        appPrompt,
    } = useContext(AppContext)

    const [ openFolders, setOpenFolders ] = useState<string[]>(JSON.parse(localStorage.getItem('folder-list-open-items') || '[]'))

    const [ folders, setFolders ] = useState<NoteFolder[]>([])

    const load = async () => {
        setFolders(await db.getFoldersRecursive())
    }

    useEffect(() => {
        load()
    }, []);

    useEffect(() => {
        localStorage.setItem('folder-list-open-items', JSON.stringify(openFolders))
    }, [ openFolders ]);

    useEffect(() => {
        if (db.initialized) {
            db.addEventListener('noteFolder', null, () => {
                load()
            })

            return () => {
                db.removeEventListener(load)
            }
        }
    }, [ db.initialized ]);

    const onIconClick = (e: any, folder: NoteFolder) => {
        e.preventDefault()
        e.stopPropagation()

        if (openFolders.includes(folder.id)) {
            setOpenFolders(openFolders.filter(id => id !== folder.id))
        } else {
            setOpenFolders([
                ...openFolders,
                folder.id,
            ])
        }
    }

    const onItemClick = (e: any, folder: NoteFolder) => {
        e.preventDefault()
        if (props.onClick) {
            props.onClick(folder)
        }
    }

    const onCreateClick = async (e: any, parent: NoteFolder | null) => {
        e.preventDefault()

        const { confirmed, value } = await appPrompt(
            'Create folder',
            'Folder name:'
        )

        if (confirmed && value) {
            await db.createFolder(value, parent?.id)
        }
    }

    const createItems = (folders: NoteFolder[]): ReactNode[] => {
        const res: ReactNode[] = []
        for (const folder of folders) {
            res.push(<li key={ folder.id }>
                <a href="#" className={`sidebar-item${props.selectedId === folder.id ? ' active' : ''}`} onClick={ e => onItemClick(e, folder) }>
                    <span className="icon" onClick={ e => onIconClick(e, folder) }>
                        <AppIcon
                            regular
                            icon={
                                openFolders.includes(folder.id)
                                ? AppIconName.FolderOpen
                                : (folder.children?.length ? AppIconName.ChevronDown : AppIconName.Folder)
                            } />
                    </span>
                    <span className="content">
                        <span className="inner">
                            {folder.title}
                        </span>
                    </span>
                </a>
                { openFolders.includes(folder.id) ? (
                    <ul>
                        { folder.children ? createItems(folder.children).map(child => child) : null }
                        <li>
                            <a href="#"
                               onClick={e => onCreateClick(e, folder)}
                               className={`sidebar-item`}>
                                <div className="icon">
                                    <AppIcon icon={AppIconName.FolderPlus} regular/>
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        {t('main.createFolder')}
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                ) : null}
            </li>)
        }
        return res
    }

    return (
        <div className={'folder-tree'}>
            <ul className="folder-tree__tree">
                {createItems(folders).map(item => item)}
            </ul>

            <hr/>

            <a href="#"
               onClick={e => onCreateClick(e, null)}
               className={`sidebar-item ${false ? ' active' : ''}`}>
                <div className="icon">
                    <AppIcon icon={AppIconName.FolderPlus} regular/>
                </div>
                <div className="content">
                    <div className="inner">
                        {t('main.createFolder')}
                    </div>
                </div>
            </a>
        </div>
    )
}

export default FolderTree