import {createContext} from "react";
import {Point} from "../types";
import {NoteObject} from "../objects/NoteObject";
import NoteImageObject from "../objects/NoteImageObject";

export enum PointerFunction {
    TEXT = 'text',
    DRAW = 'draw',
    ERASE = 'erase',
    SELECT = 'select',
    Line = 'line',
    PAN = 'pan',
    ColorChange = 'ColorChange',
}

const NoteContext = createContext<{
    note: NoteObject | null
    updateNote: (note: NoteObject) => void
    initialized: boolean
    pointerFunctionOverride: PointerFunction | null
    setPointerFunctionOverride: (f: PointerFunction | null) => void
    backAvailable: boolean
    setBackAvailable: (available: boolean) => void
    forwardAvailable: boolean
    setForwardAvailable: (available: boolean) => void
    scrollPosition: number
    pressedKeys: string[]
    deleteNote: () => void
    togglePageList: () => void
    addNewPage: () => void
    addImages: (images: NoteImageObject[]) => void
    pageListShown: boolean
    currentPageId: string
    scrollToPage: (pageId: string) => void,
    translate: Point
    scale: number
    showBackgroundSelection: boolean
    setShowBackgroundSelection: (show: boolean) => void
    showTranslator: string
    setShowTranslator: (imageData: string) => void
    inGesture: boolean
    setEncryptionPassword: (password: string) => void
}>({
    note: null,
    updateNote: () => null,
    initialized: false,
    pointerFunctionOverride: null,
    setPointerFunctionOverride: () => null,
    backAvailable: false,
    setBackAvailable: (available: boolean) => null,
    forwardAvailable: false,
    setForwardAvailable: (available: boolean) => null,
    scrollPosition: 0,
    pressedKeys: [],
    deleteNote: () => null,
    togglePageList: () => null,
    addNewPage: () => null,
    addImages: () => null,
    pageListShown: false,
    currentPageId: '',
    scrollToPage: () => null,
    translate: { x: 0, y: 0 },
    scale: 1,
    showBackgroundSelection: false,
    setShowBackgroundSelection: () => null,
    showTranslator: '',
    setShowTranslator: () => null,
    inGesture: false,
    setEncryptionPassword: () => null,
})

export default NoteContext