import './AppIcon.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faSquare as faSquareRegular,
    faSquareCheck as faSquareCheckRegular,
    faFileLines as faFileLinesRegular,
    faSun as faSunRegular,
    faMoon as faMoonRegular,
    faImage as faImageRegular,
    faKeyboard as faKeyboardRegular,
    faCircle as faCircleRegular,
    faTrashCan as faTrashCanRegular,
    faNoteSticky as faNoteStickyRegular,
    faSave as faSaveRegular,
    faFilePdf as faFilePdfRegular,
    faFile as faFileRegular,
    faFolder as faFolderRegular,
    faFolderOpen as faFolderOpenRegular,
    faFolderBlank as faFolderBlankRegular,
    faFolderClosed as faFolderClosedRegular,
} from "@fortawesome/free-regular-svg-icons";
import {
    faSquare,
    faSquareCheck,
    faFileLines,
    faSun,
    faMoon,
    faSpinner,
    faPrint,
    faImage,
    faTrash,
    faKeyboard,
    faPencil,
    faEraser,
    faUndo,
    faRedo,
    faLock,
    faLockOpen,
    faCompress,
    faExpand,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faPlus,
    faMinus,
    faCircle,
    faTimes,
    faEarthEurope,
    faBold,
    faItalic,
    faUnderline,
    faStrikethrough,
    faAlignLeft,
    faAlignCenter,
    faAlignJustify,
    faAlignRight,
    faFont,
    faBars,
    faTrashCan,
    faNoteSticky,
    faRotate,
    faRotateLeft,
    faRotateRight,
    faPalette,
    faSave,
    faDownload,
    faUpload,
    faShare,
    faEllipsis,
    faFilePdf,
    faFile,
    faFolder,
    faFolderOpen,
    faFolderBlank,
    faFolderTree,
    faFolderClosed,
    faFolderPlus,
    faFolderMinus,
} from "@fortawesome/free-solid-svg-icons";

import React, {CSSProperties, ReactElement} from "react";
import IconLine from "./icons/line/IconLine";
import IconSelect from "./icons/select/IconSelect";

export enum AppIconName {
    Checkbox = 'Checkbox',
    CheckboxChecked = 'CheckboxChecked',
    FileLines = 'FileLines',
    Sun = 'Sun',
    Moon = 'Moon',
    Plus = 'Plus',
    Minus = 'Minus',
    Spinner = 'Spinner',
    Print = 'Print',
    Image = 'Image',
    Trash = 'Trash',
    TrashCan = 'TrashCan',
    Keyboard = 'Keyboard',
    Pencil = 'Pencil',
    Eraser = 'Eraser',
    Undo = 'Undo',
    Redo = 'Redo',
    Lock = 'Lock',
    LockOpen = 'LockOpen',
    Compress = 'Compress',
    Expand = 'Expand',
    ChevronLeft = 'ChevronLeft',
    ChevronRight = 'ChevronRight',
    ChevronDown = 'ChevronDown',
    ChevronUp = 'ChevronUp',
    Circle = 'Circle',
    Times = 'Times',
    EarthEurope = 'EarthEurope',
    Bold = 'Bold',
    Italic = 'Italic',
    Underline = 'Underline',
    Strikethrough = 'Strikethrough',
    AlignLeft = 'AlignLeft',
    AlignCenter = 'AlignCenter',
    AlignJustify = 'AlignJustify',
    AlignRight = 'AlignRight',
    Font = 'Font',
    Bars = 'Bars',
    NoteSticky = 'NoteSticky',
    Rotate = 'Rotate',
    RotateLeft = 'RotateLeft',
    RotateRight = 'RotateRight',
    Palette = 'Palette',
    Save = 'Save',
    Download = 'Download',
    Upload = 'Upload',
    Share = 'Share',
    Ellipsis = 'Ellipsis',
    Line = 'Line',
    Select = 'Select',
    Pdf = 'Pdf',
    File = 'File',
    Folder = 'Folder',
    FolderOpen = 'FolderOpen',
    FolderBlank = 'FolderBlank',
    FolderTree = 'FolderTree',
    FolderClosed = 'FolderClosed',
    FolderPlus = 'FolderPlus',
    FolderMinus = 'FolderMinus',
}

type IconType = 'regular' | 'solid'

interface AppIconProps {
    icon: AppIconName
    spin?: boolean
    regular?: boolean
    rotate?: number
    active?: boolean
    color?: string
}

const AppIcon = (props: AppIconProps) => {
    if (typeof icons[props.icon] === 'undefined') {
        throw new Error('unknown app icon: ' + props.icon)
    }

    let iconName = props.icon

    if (props.active) {
        const activeIconName = activeMapping[iconName]
        if (typeof activeIconName === 'undefined') {
            // console.warn('no active icon defined for', iconName)
        } else {
            iconName = activeIconName
        }
    }

    let iconType: IconType = 'solid'

    const iconConfig = icons[iconName]
    if (props.regular) {
        if (typeof iconConfig.regular === 'undefined') {
            // console.warn('no regular icon defined for', iconName)
        } else {
            iconType = 'regular'
        }
    }

    const classNames = ['AppIcon']

    if (props.active) {
        classNames.push('active')
    }

    if (props.spin) {
        classNames.push('spin')
    }

    const icon = icons[iconName][iconType] as any

    const style: CSSProperties = {}
    if (props.rotate && !props.spin) {
        style.transform = `rotate(${props.rotate}deg)`
    }

    return (
        <div className={ classNames.join(' ') } style={ style }>
            { React.createElement(icon.type, {
                ...icon.props,
                color: props.color,
                spin: props.spin
            }) }
        </div>
    )
}

const activeMapping: { [defaultIcon: string]: AppIconName } = {
    [AppIconName.Checkbox]: AppIconName.CheckboxChecked,
    [AppIconName.Sun]: AppIconName.Moon,
    [AppIconName.Moon]: AppIconName.Sun,
}

const icons: {
    [iconName: string]: {
        solid: ReactElement
        regular?: ReactElement
    }
} = {
    [AppIconName.Checkbox]: {
        solid: <FontAwesomeIcon icon={ faSquare }/>,
        regular: <FontAwesomeIcon icon={ faSquareRegular }/>,
    },
    [AppIconName.CheckboxChecked]: {
        solid: <FontAwesomeIcon icon={ faSquareCheck }/>,
        regular: <FontAwesomeIcon icon={ faSquareCheckRegular }/>,
    },
    [AppIconName.FileLines]: {
        solid: <FontAwesomeIcon icon={ faFileLines }/>,
        regular: <FontAwesomeIcon icon={ faFileLinesRegular }/>,
    },
    [AppIconName.Sun]: {
        solid: <FontAwesomeIcon icon={ faSun }/>,
        regular: <FontAwesomeIcon icon={ faSunRegular }/>,
    },
    [AppIconName.Moon]: {
        solid: <FontAwesomeIcon icon={ faMoon }/>,
        regular: <FontAwesomeIcon icon={ faMoonRegular }/>,
    },
    [AppIconName.Plus]: {
        solid: <FontAwesomeIcon icon={ faPlus }/>,
    },
    [AppIconName.Minus]: {
        solid: <FontAwesomeIcon icon={ faMinus }/>,
    },
    [AppIconName.Spinner]: {
        solid: <FontAwesomeIcon icon={ faSpinner }/>,
    },
    [AppIconName.Print]: {
        solid: <FontAwesomeIcon icon={ faPrint }/>,
    },
    [AppIconName.Image]: {
        solid: <FontAwesomeIcon icon={ faImage }/>,
        regular: <FontAwesomeIcon icon={ faImageRegular }/>,
    },
    [AppIconName.Trash]: {
        solid: <FontAwesomeIcon icon={ faTrash }/>,
    },
    [AppIconName.Keyboard]: {
        solid: <FontAwesomeIcon icon={ faKeyboard }/>,
        regular: <FontAwesomeIcon icon={ faKeyboardRegular }/>,
    },
    [AppIconName.Pencil]: {
        solid: <FontAwesomeIcon icon={ faPencil }/>,
    },
    [AppIconName.Eraser]: {
        solid: <FontAwesomeIcon icon={ faEraser }/>,
    },
    [AppIconName.Undo]: {
        solid: <FontAwesomeIcon icon={ faUndo }/>,
    },
    [AppIconName.Redo]: {
        solid: <FontAwesomeIcon icon={ faRedo }/>,
    },
    [AppIconName.Lock]: {
        solid: <FontAwesomeIcon icon={ faLock }/>,
    },
    [AppIconName.LockOpen]: {
        solid: <FontAwesomeIcon icon={ faLockOpen }/>,
    },
    [AppIconName.Compress]: {
        solid: <FontAwesomeIcon icon={ faCompress }/>,
    },
    [AppIconName.Expand]: {
        solid: <FontAwesomeIcon icon={ faExpand }/>,
    },
    [AppIconName.ChevronLeft]: {
        solid: <FontAwesomeIcon icon={ faChevronLeft }/>,
    },
    [AppIconName.ChevronRight]: {
        solid: <FontAwesomeIcon icon={ faChevronRight }/>,
    },
    [AppIconName.ChevronUp]: {
        solid: <FontAwesomeIcon icon={ faChevronUp }/>,
    },
    [AppIconName.ChevronDown]: {
        solid: <FontAwesomeIcon icon={ faChevronDown }/>,
    },
    [AppIconName.Circle]: {
        solid: <FontAwesomeIcon icon={ faCircle }/>,
        regular: <FontAwesomeIcon icon={ faCircleRegular }/>,
    },
    [AppIconName.Times]: {
        solid: <FontAwesomeIcon icon={ faTimes }/>,
    },
    [AppIconName.EarthEurope]: {
        solid: <FontAwesomeIcon icon={ faEarthEurope }/>,
    },
    [AppIconName.Bold]: {
        solid: <FontAwesomeIcon icon={ faBold }/>,
    },
    [AppIconName.Italic]: {
        solid: <FontAwesomeIcon icon={ faItalic }/>,
    },
    [AppIconName.Underline]: {
        solid: <FontAwesomeIcon icon={ faUnderline }/>,
    },
    [AppIconName.Strikethrough]: {
        solid: <FontAwesomeIcon icon={ faStrikethrough }/>,
    },
    [AppIconName.AlignLeft]: {
        solid: <FontAwesomeIcon icon={ faAlignLeft }/>,
    },
    [AppIconName.AlignCenter]: {
        solid: <FontAwesomeIcon icon={ faAlignCenter }/>,
    },
    [AppIconName.AlignJustify]: {
        solid: <FontAwesomeIcon icon={ faAlignJustify }/>,
    },
    [AppIconName.AlignRight]: {
        solid: <FontAwesomeIcon icon={ faAlignRight }/>,
    },
    [AppIconName.Font]: {
        solid: <FontAwesomeIcon icon={ faFont }/>,
    },
    [AppIconName.Bars]: {
        solid: <FontAwesomeIcon icon={ faBars }/>,
    },
    [AppIconName.TrashCan]: {
        solid: <FontAwesomeIcon icon={ faTrashCan }/>,
        regular: <FontAwesomeIcon icon={ faTrashCanRegular }/>,
    },
    [AppIconName.NoteSticky]: {
        solid: <FontAwesomeIcon icon={ faNoteSticky }/>,
        regular: <FontAwesomeIcon icon={ faNoteStickyRegular }/>,
    },
    [AppIconName.Rotate]: {
        solid: <FontAwesomeIcon icon={ faRotate }/>,
    },
    [AppIconName.RotateLeft]: {
        solid: <FontAwesomeIcon icon={ faRotateLeft }/>,
    },
    [AppIconName.RotateRight]: {
        solid: <FontAwesomeIcon icon={ faRotateRight }/>,
    },
    [AppIconName.Palette]: {
        solid: <FontAwesomeIcon icon={ faPalette }/>,
    },
    [AppIconName.Save]: {
        solid: <FontAwesomeIcon icon={ faSave }/>,
        regular: <FontAwesomeIcon icon={ faSaveRegular }/>,
    },
    [AppIconName.Download]: {
        solid: <FontAwesomeIcon icon={ faDownload }/>,
    },
    [AppIconName.Upload]: {
        solid: <FontAwesomeIcon icon={ faUpload }/>,
    },
    [AppIconName.Share]: {
        solid: <FontAwesomeIcon icon={ faShare }/>,
    },
    [AppIconName.Ellipsis]: {
        solid: <FontAwesomeIcon icon={ faEllipsis }/>,
    },
    [AppIconName.File]: {
        solid: <FontAwesomeIcon icon={ faFile }/>,
        regular: <FontAwesomeIcon icon={ faFileRegular }/>,
    },
    [AppIconName.Pdf]: {
        solid: <FontAwesomeIcon icon={ faFilePdf }/>,
        regular: <FontAwesomeIcon icon={ faFilePdfRegular }/>,
    },
    [AppIconName.Line]: {
        solid: <IconLine/>,
    },
    [AppIconName.Select]: {
        solid: <IconSelect/>,
    },
    [AppIconName.Folder]: {
        solid: <FontAwesomeIcon icon={ faFolder }/>,
        regular: <FontAwesomeIcon icon={ faFolderRegular }/>,
    },
    [AppIconName.FolderOpen]: {
        solid: <FontAwesomeIcon icon={ faFolderOpen }/>,
        regular: <FontAwesomeIcon icon={ faFolderOpenRegular }/>,
    },
    [AppIconName.FolderBlank]: {
        solid: <FontAwesomeIcon icon={ faFolderBlank }/>,
        regular: <FontAwesomeIcon icon={ faFolderBlankRegular }/>,
    },
    [AppIconName.FolderClosed]: {
        solid: <FontAwesomeIcon icon={ faFolderClosed }/>,
        regular: <FontAwesomeIcon icon={ faFolderClosedRegular }/>,
    },
    [AppIconName.FolderTree]: {
        solid: <FontAwesomeIcon icon={ faFolderTree }/>,
    },
    [AppIconName.FolderPlus]: {
        solid: <FontAwesomeIcon icon={ faFolderPlus }/>,
    },
    [AppIconName.FolderMinus]: {
        solid: <FontAwesomeIcon icon={ faFolderMinus }/>,
    },
}

export default AppIcon