import {useContext} from "react";
import AppContext from "../context/AppContext";

export type HandwriteToTextResult = {
    ok: boolean
    result: string
    language: string
    raw: any
}

export type SupportedLanguage = {
    displayName: string
    languageCode: string
    supportSource: boolean
    supportTarget: boolean
}

export type Translation = {
    translation: string
    sourceLanguageCode: string
    destLanguageCode: string
}

export enum TTSGender {
    MALE = 'm',
    FEMALE = 'f',
    NEUTRAL = 'n',
}

const apiKey = '4d725d58e464663ffd883fd0adf41692c65cf4c3dd8a24017f467501a9c07f2d'

export default function useApi() {
    const {
        apiBaseURL,
        supportedLanguages,
        setSupportedLanguages,
    } = useContext(AppContext)

    const handwriteToText = async (imageDataB64: string, languageCode: string|null = null): Promise<HandwriteToTextResult> => {
        const formData = new FormData()
        formData.set('imageData', imageDataB64)

        let url = buildUrl('vision')
        if (languageCode) {
            url += `?languageCode=${encodeURIComponent(languageCode)}`
        }

        const res = await fetch(url, {
            method: 'POST',
            body: formData,
        })
        const json = await res.json()

        return {
            ok: !!json.result,
            result: json.result,
            language: json.language,
            raw: json.raw,
        }
    }

    const getSupportedLanguages = async (displayLanguage: string): Promise<SupportedLanguage[]> => {
        if (typeof supportedLanguages[displayLanguage] !== 'undefined') {
            return supportedLanguages[displayLanguage]
        }

        const res = await fetch(buildUrl(`languages=${encodeURIComponent(displayLanguage)}`))
        const languages = await res.json()

        setSupportedLanguages(displayLanguage, languages)

        return languages
    }

    const translate = async (input: string, destLanguageCode: string, sourceLanguageCode: string|null = null): Promise<Translation> => {
        let url = buildUrl(`translate=${encodeURIComponent(input)}&to=${encodeURIComponent(destLanguageCode)}`)
        if (sourceLanguageCode) {
            url += `&from=${encodeURIComponent(sourceLanguageCode)}`
        }
        const res = await fetch(url)
        return await res.json()
    }

    const ttsUrl = (input: string, languageCode: string|null = null, languageName: string|null = null, gender: TTSGender|null = TTSGender.FEMALE) => {
        let url = buildUrl(`tts=${encodeURIComponent(input)}`)
        if (languageCode) {
            url += `&languageCode=${encodeURIComponent(languageCode)}`
        }
        if (languageName) {
            url += `&languageName=${encodeURIComponent(languageName)}`
        }
        if (gender) {
            url += `&gender=${encodeURIComponent(gender)}`
        }
        return url
    }

    const share = async (type: 'note' | 'pdf', noteId: string, noteTitle: string, body: Blob) => {
        const formData = new FormData()
        formData.append('file', new File([body], 'Note.pdf', { type: 'application/pdf' }))

        const url = buildUrl(`createShare=${encodeURIComponent(type)}&noteId=${encodeURIComponent(noteId)}&noteTitle=${encodeURIComponent(noteTitle)}`)

        const res = await fetch(url, {
            method: 'POST',
            body: formData,
        })
        return await res.json()
    }

    const sharedNote = async (id: string) => {
        const url = buildUrl(`share=${id}&type=raw`)

        const res = await fetch(url)
        return await res.json()
    }

    const buildUrl = (query: string) => {
        return `${apiBaseURL}?key=${encodeURIComponent(apiKey)}&${query}`
    }

    return {
        handwriteToText,
        getSupportedLanguages,
        translate,
        ttsUrl,
        share,
        sharedNote,
    }
}