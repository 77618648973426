import './NotePreview.scss'
import {NoteObject} from "../objects/NoteObject";
import {Link, useNavigate} from "react-router-dom";
import React, {createRef, useEffect, useRef, useState} from "react";
import AppIcon, {AppIconName} from "./AppIcon";
import {Point} from "../types";

interface NotePreviewProps {
    note: NoteObject
    selected: boolean
    inSelectionMode: boolean
    onSelect: () => void
}

const NotePreview = (props: NotePreviewProps) => {
    const navigate = useNavigate()

    const [ pressCheckTimeout, setPressCheckTimeout ] = useState<any>(null)
    const [ wasLongPressed, setWasLongPressed ] = useState<boolean>(false)
    const [ pressStart, setPressStart ] = useState<Point>({ x: -1, y: -1 })

    const [ dragClone, setDragClone ] = useState<HTMLDivElement | null>(null)

    const ref = createRef<HTMLDivElement>()

    const dragRef = useRef(dragClone)
    dragRef.current = dragClone

    const timeoutRef = useRef(pressCheckTimeout)
    timeoutRef.current = pressCheckTimeout

    const pressStartRef = useRef(pressStart)
    pressStartRef.current = pressStart

    const wasLongPressedRef = useRef(wasLongPressed)
    wasLongPressedRef.current = wasLongPressed

    const onDragStart = (el: HTMLDivElement) => {
        return
        if (el) {
            const box = el.getBoundingClientRect()

            const clone = document.createElement('div')
            clone.className = 'NotePreview drag-clone'
            clone.innerHTML = el.innerHTML
            clone.style.width = `${box.width}px`
            clone.style.height = `${box.height}px`
            clone.style.left = `${box.left}px`
            clone.style.top = `${box.top}px`
            clone.setAttribute('orig-left', box.left + '')
            clone.setAttribute('orig-top', box.top + '')
            clone.addEventListener('pointerup', onPointerUp)
            document.body.appendChild(clone)

            setTimeout(() => {
                clone.classList.add('initialized')
            }, 10)

            setDragClone(clone)
        }
    }

    const onDragStop = (e: React.PointerEvent | PointerEvent) => {
        if (dragRef.current) {
            const el = dragRef.current

            const left = parseInt(el.getAttribute('orig-left') || '')
            const top = parseInt(el.getAttribute('orig-top') || '')

            el.classList.add('falling-back')
            el.style.left = `${left}px`
            el.style.top = `${top}px`

            setTimeout(() => {
                el.parentNode?.removeChild(el)
            }, 500)
            setDragClone(null)
        }
    }

    const onPointerDown = (e: React.PointerEvent) => {
        e.preventDefault()
        e.stopPropagation()

        if (props.inSelectionMode) {
            return
        }
        if (pressCheckTimeout) {
            window.clearTimeout(pressCheckTimeout)
        }
        setPressStart({ x: e.clientX, y: e.clientY })
        setPressCheckTimeout(
            setTimeout(() => {
                setWasLongPressed(true)
                props.onSelect()
            }, 500)
        )
    }

    const onPointerMove = (e: React.PointerEvent | PointerEvent) => {
        e.preventDefault()
        e.stopPropagation()

        if (dragRef.current) {
            dragRef.current.style.left = `${parseInt(dragRef.current.style.left) + e.movementX}px`
            dragRef.current.style.top = `${parseInt(dragRef.current.style.top) + e.movementY}px`
            return
        }

        if (timeoutRef.current) {
            const diffX = e.clientX - pressStartRef.current.x
            const diffY = e.clientY - pressStartRef.current.y

            if (diffX > 5 || diffX < -5 || diffY > 5 || diffY < -5) {
                clearTimeout(timeoutRef.current)
                setPressCheckTimeout(null)

                const el = document.querySelector(`.NotePreview[data-note-id="${props.note.id}"]`) as HTMLDivElement|null
                if (el) {
                    onDragStart(el)
                }
            }
        }
    }

    const onPointerUp = (e: React.PointerEvent | PointerEvent) => {
        if (dragRef.current) {
            console.log('stop drag')
            onDragStop(e)
            return
        }

        if (wasLongPressedRef.current) {
            setWasLongPressed(false)
            return
        }

        if (props.inSelectionMode) {
            props.onSelect()
            return
        }

        navigate(`/note/${props.note.id}`)
    }

    useEffect(() => {
        window.addEventListener('pointermove', onPointerMove)
        window.addEventListener('pointerup', onDragStop)

        return () => {
            window.removeEventListener('pointermove', onPointerMove)
            window.removeEventListener('pointerup', onDragStop)

            if (pressCheckTimeout) {
                window.clearTimeout(pressCheckTimeout)
            }
        }
    }, []);

    const classNames = ['NotePreview']
    if (props.selected) {
        classNames.push('selection-mode')
    }
    if (props.selected) {
        classNames.push('selected')
    }

    return (
        <div className={ classNames.join(' ') } ref={ ref } data-note-id={ props.note.id }>
            <Link to={`/note/${props.note.id}`}
                  onClick={ e => e.preventDefault() }
                  onTouchStart={ e => e.preventDefault() }
                  onContextMenu={ e => e.preventDefault() }
                  onPointerDown={ onPointerDown }
                  onPointerUp={ onPointerUp }
                  className={'NotePreview'}>

                <img src={ props.note.previewImage }/>

                <div className="title">
                    { props.note.title }
                </div>

                { props.inSelectionMode ? (
                    <AppIcon icon={ AppIconName.Checkbox }
                             active={ props.selected }
                             regular={ !props.selected }/>
                ) : null}
            </Link>
        </div>
    )
}

export default NotePreview