import './SidebarContainer.scss'
import {ReactNode, useEffect, useState} from "react";
import AppIcon, {AppIconName} from "./AppIcon";

interface SidebarContainerProps {
    sidebar: ReactNode
    content: ReactNode
    onExpanded: (expanded: boolean) => void
}

const SidebarContainer = (props: SidebarContainerProps) => {
    const [ expanded, setExpanded ] = useState<boolean>(false)

    const onToggleExpanded = (e: any) => {
        e.preventDefault()
        setExpanded(!expanded)
    }

    useEffect(() => {
        props.onExpanded(expanded)
    }, [ expanded ]);

    const classes = ['sidebar-container']
    if (expanded) {
        classes.push('expanded')
    }

    return (
        <div className={ classes.join(' ') }>
            <div className="sidebar-container__sidebar">
                <div className="inner">
                    <a href="#" onClick={onToggleExpanded}>
                        <AppIcon icon={AppIconName.Bars}/>
                    </a>
                    <div className="content">
                        {props.sidebar}
                    </div>
                </div>
            </div>
            <div className="sidebar-container__content">
                {props.content}
            </div>
        </div>
    )
}

export default SidebarContainer